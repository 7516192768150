<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->

      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-between">
        <!-- Search -->
        <search-input
          :placeholder="'Cari Bundle berdasarkan Nama...'"
          :onInput="onSearch"
        />
        <b-button
          v-b-modal.add-bundle
          style="border-radius: 15px; height: 48px"
          variant="primary"
        >
          <span class="text-sm text-nowrap">Tambah Bundle</span>
        </b-button>
      </div>
    </div>

    <!-- Table Contianer Card -->
    <b-card no-body class="mb-0">
      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Data tidak ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">
          <p>
            {{ formatDate(data.value) }}
          </p>
        </template>

        <template #cell(updated_at)="data">
          <p>
            {{ formatDate(data.value) }}
          </p>
        </template>

        <template #cell(harga)="data">
          <p>
            {{ rupiah(data.value) }}
          </p>
        </template>

        <template #cell(harga_diskon)="data">
          <p>
            {{ rupiah(data.value) }}
          </p>
        </template>

        <template #cell(is_active)="data">
          <p>
            {{ !!data.value ? "Aktif" : "Non-aktif" }}
          </p>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{
                name: 'manajemen-bundle-detail',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Detail Bundle</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.edit-bundle
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.delete-bundle
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="totalPage"
          :total="totalData"
        />
      </div>
    </b-card>

    <!-- Add Bundle -->
    <b-modal
      id="add-bundle"
      size="md"
      centered
      title="Tambah Bundle"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="submitBundle"
    >
      <b-form>
        <b-form-group label="Nama Bundle" label-for="nama">
          <b-form-input
            v-model="formBundle.name"
            id="nama"
            placeholder="Masukkan Nama Bundle"
          />
        </b-form-group>

        <b-form-group
          v-if="previewUrl"
          label="Thumbnail"
          class="tw-flex tw-justify-center"
        >
          <b-img
            v-if="previewUrl"
            :src="previewUrl"
            width="200px"
            height="200px"
            rounded
            alt="Thumbnail"
          ></b-img>
        </b-form-group>

        <b-form-group label="Pilih Thumbnail" label-for="cover">
          <b-form-file
            id="cover"
            v-model="formBundle.cover"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
            @change="handleFileUpload"
          />
        </b-form-group>

        <b-form-group label="Kategori" label-for="kategori">
          <b-form-input
            v-model="formBundle.bundle_category"
            id="bundle_category"
            placeholder="Pilih kategori"
          />
        </b-form-group>

        <b-form-group label="Deskripsi" label-for="deskripsi">
          <b-form-input
            v-model="formBundle.description"
            id="deskripsi"
            placeholder="Masukkan deskripsi bundle"
          />
        </b-form-group>

        <b-form-group label="Event" label-for="event">
          <v-select
            id="event_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="listEvent"
            :reduce="(event) => event.name"
            @option:selected="onChangeEvent"
          />
        </b-form-group>

        <b-form-group label="Jenis" label-for="jenis">
          <v-select
            id="jenis_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nama"
            :options="listJenis"
            :reduce="(jenis) => jenis.nama"
            @option:selected="onChangeJenis"
          />
        </b-form-group>

        <b-form-group label="Harga" label-for="harga">
          <b-form-input
            v-model="formBundle.harga"
            id="harga"
            placeholder="Masukkan Harga Bundle"
          />
        </b-form-group>

        <b-form-group label="Harga Diskon" label-for="hargaDiskon">
          <b-form-input
            v-model="formBundle.harga_diskon"
            id="hargaDiskon"
            placeholder="Masukkan Harga Diskon Bundle"
          />
        </b-form-group>

        <div class="tw-flex tw-items-center tw-gap-3">
          <label for="show">Aktif</label>
          <b-form-checkbox
            id="show"
            :checked="formBundle.is_active"
            v-model="formBundle.is_active"
            class=""
            name="check-button"
            switch
          />
        </div>
      </b-form>
    </b-modal>

    <!-- Edit Bundle -->
    <b-modal
      id="edit-bundle"
      size="md"
      centered
      title="Edit Bundle"
      cancel-title="Close"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-variant="outline-secondary"
      @show="fetchDetailBundle"
      @hidden="resetModal"
      @ok="editBundle"
    >
      <b-form>
        <b-form-group label="Nama Bundle" label-for="nama">
          <b-form-input
            v-model="formBundle.name"
            id="nama"
            placeholder="Masukkan Nama Bundle"
          />
        </b-form-group>

        <b-form-group
          v-if="previewUrl"
          label="Thumbnail"
          class="tw-flex tw-justify-center"
        >
          <b-img
            v-if="previewUrl"
            :src="previewUrl"
            width="200px"
            height="200px"
            rounded
            alt="Thumbnail"
          ></b-img>
        </b-form-group>

        <b-form-group label="Pilih Thumbnail" label-for="cover">
          <b-form-file
            id="cover"
            v-model="formBundle.cover"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg, .png"
            @change="handleFileUpload"
          />
        </b-form-group>

        <b-form-group label="Kategori" label-for="kategori">
          <b-form-input
            v-model="formBundle.bundle_category"
            id="bundle_category"
            placeholder="Pilih kategori"
          />
        </b-form-group>

        <b-form-group label="Deskripsi" label-for="deskripsi">
          <b-form-input
            v-model="formBundle.description"
            id="deskripsi"
            placeholder="Masukkan deskripsi bundle"
          />
        </b-form-group>

        <b-form-group label="Event" label-for="event">
          <v-select
            id="event_id"
            v-model="formBundle.event"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="listEvent"
            :reduce="(event) => event.name"
            @option:selected="onChangeEvent"
          />
        </b-form-group>

        <b-form-group label="Jenis" label-for="jenis">
          <v-select
            id="jenis_id"
            v-model="formBundle.jenis"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nama"
            :options="listJenis"
            :reduce="(jenis) => jenis.nama"
            @option:selected="onChangeJenis"
          />
        </b-form-group>

        <b-form-group label="Harga" label-for="harga">
          <b-form-input
            v-model="formBundle.harga"
            id="harga"
            placeholder="Masukkan Harga Bundle"
          />
        </b-form-group>

        <b-form-group label="Harga Diskon" label-for="hargaDiskon">
          <b-form-input
            v-model="formBundle.harga_diskon"
            id="hargaDiskon"
            placeholder="Masukkan Harga Diskon Bundle"
          />
        </b-form-group>

        <div class="tw-flex tw-items-center tw-gap-3">
          <label for="show">Aktif</label>
          <b-form-checkbox
            id="show"
            :checked="formBundle.is_active"
            v-model="formBundle.is_active"
            class=""
            name="check-button"
            switch
          />
        </div>
      </b-form>
    </b-modal>

    <!-- Delete Bundle -->
    <b-modal
      id="delete-bundle"
      cancel-variant="outline-secondary"
      :ok-title-html="`${isLoadingSubmit ? 'Loading..' : 'Save'}`"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="isLoadingSubmit"
      :cancel-disabled="isLoadingSubmit"
      :hide-header-close="isLoadingSubmit"
      cancel-title="Close"
      centered
      title="Hapus Bundle"
      @ok="deleteBundle"
    >
      <b-form>
        <b-form-group>
          <p>Apakah anda akan menghapus Bundle ini?</p>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
// Vue
import { defineComponent, ref, onMounted, watch } from "@vue/composition-api";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";

// Custom Component
import CustomFilter from "@/@core/components/base/CustomFilter.vue";
import SearchInput from "@/@core/components/base/SearchInput.vue";
import Pagination from "@/@core/components/base/Pagination.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Bootstrap
import {
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BCard,
  BSpinner,
  BFormInput,
  BModal,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormFile,
  BImg,
} from "bootstrap-vue";


// Utils
import { useRouter, formatDate, rupiah } from "@core/utils/utils";
import debounce from "lodash.debounce";
import router from "@/router";

// API
import repository from "@repofactory";
const repoBundles = repository.get("BundleRepository");
const repoEvent = repository.get("EventRepository");
const repoJenis = repository.get("JenisRepository");

export default defineComponent({
  components: {
    SearchInput,
    BButton,
    CustomFilter,
    BTable,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Pagination,
    BCard,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BFormFile,
    BImg
  },
  setup() {
    const toast = useToast();
    const { route } = useRouter();

    const data = ref([]);
    const isLoading = ref(false);
    const isLoadingSubmit = ref(false);
    const listJenis = ref([])
    const listEvent = ref([])

    const DEFAULT_FORM_BUNDLE = {
      name: null,
      description: null,
      cover: null,
      event: null,
      jenis: null,
      bundle_category: null,
      is_active: true,
      harga: null,
      harga_diskon: null
    }

    const formBundle = ref({
      ...DEFAULT_FORM_BUNDLE
    });

    const product_id = ref(null);

    const setTargetedId = (value) => {
      product_id.value = value;
    };

    const formAddDetail = ref({
      nama: null,
      link: null,
      deskripsi: null,
      preview_image: null,
    });

    const modalAddDetails = ref(null);

    const tableColumns = [
      { key: "id", label: "ID" },
      { key: "name", label: "NAMA BUNDLE" },
      { key: "bundle_category", label: "KATEGORI" },
      { key: "description", label: "DESKRIPSI" },
      { key: "jenis.nama", label: "JENIS" },
      { key: "event.name", label: "EVENT" },
      { key: "harga", label: "HARGA" },
      { key: "harga_diskon", label: "HARGA DISKON" },
      { key: "total_tryout", label: "TOTAL TRY OUT" },
      { key: "total_video", label: "TOTAL VIDEO" },
      { key: "total_materi", label: "TOTAL MATERI" },
      { key: "created_at", label: "DIBUAT" },
      { key: "updated_at", label: "DIUBAH" },
      { key: "is_active", label: "STATUS" },
      { key: "actions" },
    ];

    const keyword = ref("");

    let totalPage = ref(1);
    let totalData = ref(1);
    let from = ref(1);
    let to = ref(1);

    const onSearch = (e) => {
      keyword.value = e.currentTarget.value;
    };

    const handleChange = (value) => {
      filterValue.value = { ...value };
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await repoBundles.get(route.value.query.page, 10, keyword.value)
        data.value = res.data.data.data
        isLoading.value = false
        totalData.value = res.data.data.total
        from.value = res.data.data.from
        to.value = res.data.data.to
        totalPage.value = res.data.data.last_page
      } catch (e) {
        isLoading.value = false;
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Fetching data bundles",
            "danger",
            "AlertTriangleIcon"
          );
        }

        isLoading.value = false;
      }
    };

    const fetchEvent = async () => {
      try {
        const res = await repoEvent.get();
        listEvent.value = res.data.data.data;
      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Fetching data event",
            "danger",
            "AlertTriangleIcon"
          );
        }
      }
    };

    const fetchJenis = async () => {
      try {
        const res = await repoJenis.get();
        listJenis.value = res.data.data;
      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Fetching data jenis",
            "danger",
            "AlertTriangleIcon"
          );
        }
      }
    };

    const onChangeJenis = (value) => {
      formBundle.value.jenis = value
    }

    const onChangeEvent = (value) => {
      formBundle.value.event = value
    }

    const assignFormData = (payload) => {
      let formData = new FormData()
      const { name, description, cover, event, jenis, bundle_category, is_active, harga, harga_diskon } = payload
      formData.append("name", name)
      formData.append("description", description)
      if(cover) {
        formData.append("cover", cover)
      }
      formData.append("event_id", event.id)
      formData.append("jenis_id", jenis.id)
      formData.append("bundle_category", bundle_category)
      formData.append("harga", harga)
      formData.append("harga_diskon", harga_diskon)
      formData.append("is_active", is_active)
      return formData
    }

    const submitBundle = async (bvModalEvent) => {
      bvModalEvent.preventDefault();
      try {
        isLoadingSubmit.value = true;
        const formData = assignFormData(formBundle.value)
        const res = await repoBundles.create(formData);
        fetchData()
        showToast("Notifikasi", res.data.message, "primary");
        bvModalEvent.vueTarget.hide()
      } catch (e) {
        isLoadingSubmit.value = false;
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Submit data bundle",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false;
      }
    };

    const editBundle = async (bvModalEvent) => {
      bvModalEvent.preventDefault();
      try {
        isLoadingSubmit.value = true;
        const formData = assignFormData(formBundle.value)
        const res = await repoBundles.update(product_id.value, formData);
        fetchData()
        showToast("Notifikasi", res.data.message, "primary");
        bvModalEvent.vueTarget.hide()
      } catch (e) {
        isLoadingSubmit.value = false;
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error edit data bundle",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false;
      }
    };

    const submitDetailProduct = async (modal) => {
      try {
        modal.preventDefault();
        isLoadingSubmit.value = true;
        const res = await repoProducts.createDetail(
          product_id.value,
          formAddDetail.value
        );
        showToast("Notifikasi", res.data.message, "primary");
        modalAddDetails.value.hide();
        router.push(`/ringkasan-materi/${product_id.value}`);
      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error Submit data products",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false;
      }
    };

    const deleteBundle = async (modal) => {
      try {
        isLoadingSubmit.value = true;
        modal.preventDefault();
        const res = await repoBundles.delete(product_id.value);
        showToast("Notifikasi", res.data.message, "primary");
        modal.vueTarget.hide();
        fetchData();
      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error delete data bundle",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoadingSubmit.value = false;
      }
    };

    const fetchDetailBundle = async () => {
      try {
        const res = await repoBundles.getDetail(product_id.value);
        const data = res.data.data;

        previewUrl.value = data.cover

        const event = listEvent.value.find(item => item.id === data.event_id)
        const jenis = listJenis.value.find(item => item.id === data.jenis_id)

        formBundle.value = {
          ...formBundle.value,
          name: data.name,
          description: data.description,
          bundle_category: data.bundle_category,
          jenis: jenis,
          event: event,
          is_active: !!data.is_active,
          cover: null,
          harga: data.harga,
          harga_diskon: data.harga_diskon
        };
      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error fetching data bundle detail",
            "danger",
            "AlertTriangleIcon"
          );
        }
      }
    };

    onMounted(() => {
      fetchData();
      fetchJenis();
      fetchEvent();
    });

    watch([keyword], debounce(fetchData, 500));

    const resetModal = () => {
      formBundle.value = {
        ...DEFAULT_FORM_BUNDLE
      };
      previewUrl.value = null
    };

    const resetModalDetail = () => {
      formAddDetail.value = {
        nama: null,
        link: null,
        deskripsi: null,
        preview_image: null,
      };
    };

    const previewUrl = ref(null);

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        formBundle.value.cover = file;
        previewUrl.value = URL.createObjectURL(file);
      }
    };

    return {
      // state
      data,
      keyword,
      tableColumns,
      totalPage,
      totalData,
      from,
      to,
      isLoading,
      formAddDetail,
      modalAddDetails,
      isLoadingSubmit,
      product_id,
      formBundle,
      listJenis,
      listEvent,
      previewUrl,

      // method
      onSearch,
      handleChange,
      formatDate,
      rupiah,
      resetModal,
      submitBundle,
      resetModalDetail,
      submitDetailProduct,
      setTargetedId,
      onChangeJenis,
      onChangeEvent,
      handleFileUpload,
      deleteBundle,
      fetchDetailBundle,
      editBundle,
    };
  },
});
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
